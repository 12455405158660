<template>
  <v-dialog v-model="activo" width="700">
    <v-card>
      <v-card-title class="" height="15%">
        <span class="">Ver Paquete</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6 px-4 py-1">
        <v-form ref="formPaquetes" v-model="formPaquete" class="pa-2">
          <v-row>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                class="mb-0 pb-0"
                v-model="cliente" 
                label="Apellido y Nombre" 
                filled readonly outlined dense 
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0" :class="pantallaChica ? 'pt-0' : ''">
              <v-text-field 
                v-model="documento" 
                label="Documento" 
                type="number" 
                filled readonly outlined dense></v-text-field>
            </v-col>
            <v-col cols="12"  sm="6" md="6" class="pb-0 pt-0">
              <v-text-field v-model="direccion" outlined label="Direccion" readonly filled dense></v-text-field>
            </v-col>
            <v-col cols="12"  sm="12" md="6" class="pb-0 pt-0">
              <v-text-field v-model="localidad" outlined label="Localidad" readonly filled dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0 pt-0">
              <v-text-field v-model="telefono" outlined label="Telefono" readonly filled dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" :class="pantallaChica ? 'pt-0 pb-6' : 'pb-0 pt-0'">
              <v-autocomplete 
                v-model="deBodega" 
                :items="bodegasO" 
                item-text="bodega_nombre"
                hide-details
                label="De Bodega" 
                dense
                readonly
                filled
                return-object
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" :class="pantallaChica ? 'pt-0 pb-2' : 'pb-0 pt-0'">
              <v-autocomplete 
                v-model="aBodega" 
                :items="bodegasD" 
                item-text="bodega_nombre"
                hide-details
                readonly
                filled
                label="A Bodega" 
                dense
                return-object
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="observacion" outlined counter :rows="2" filled label="Observacion" class="pt-2 pb-0" readonly dense></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="detallesPaquete.length > 0">
          <v-col cols="12">
            <div v-text="'Detalle de Paquete'" class="pl-2" style="font-size: 20px; font-weight: bold;"></div>
          </v-col>
          <v-col cols="12">
            <v-data-table 
              :headers="itemsHeaders" 
              :items="detallesPaquete" 
              dense
            ></v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="$vuetify.theme.dark ?  'orange' : 'secondary' " @click="activo = false">Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalVerPaquete',
  props: {
    ver: {
      type: Boolean,
      default: false
    },
    datos: {
      type: Object,
      default: {}
    },
    bodegasDestino: {
      type: Array,
      default: []
    },
    bodegasOrigen: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      itemsHeaders: [
        { text: 'Item Codigo', align: 'center', value: 'item_codigo'},
        { text: 'Item Nombre', align: 'center', value: 'item_nombre'},
        { text: 'Cantidad', align: 'center', value: 'item_cantidad'}
      ],
      formPaquete: false,
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  computed: {
    activo: {
      get(){
        return this.ver
      },
      set(value){
        if (value == false){
          this.$emit('closeModalVer', value)
        }
      }
    },
    detallesPaquete: {
      get(){
        return this.datos.itemsPaquete
      }
    },
    cliente: {
      get(){
        return this.datos.cliente
      }
    },
    documento: {
      get(){
        return this.datos.documento
      }
    },
    telefono: {
      get(){
        return this.datos.telefono
      }
    },
    direccion: {
      get(){
        return this.datos.direccion
      }
    },
    localidad: {
      get(){
        return this.datos.localidad
      }
    },
    deBodega: {
      get(){
        return this.datos.deBodega
      }
    },
    aBodega: {
      get(){
        return this.datos.aBodega
      }
    },
    observacion: {
      get(){
        return this.datos.observacion
      }
    },
    bodegasD: {
      get(){
        return this.bodegasDestino
      } 
    },
    bodegasO: {
      get(){
        return this.bodegasOrigen
      } 
    }
  },
  watch:{
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  }
}
</script>

<style>

</style>